import generatePath from "../../../helpers/generatePath"

/**
 *
 * @param {object} sanityPage
 */
export const getPathAndLabel = sanityPage => {
  const url = generatePath(sanityPage)
  let label = ""

  if (url?.includes("blog/")) label = "blog"
  else if (url?.includes("case-studies/")) label = "case study"
  else if (url?.includes("events/")) label = "events"
  else if (url?.includes("resources/")) label = "resources"

  return { url, label }
}
