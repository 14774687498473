// extracted by mini-css-extract-plugin
export var sbShowMain = "moreReading-module--sb-show-main--3gnWf";
export var bigCardContainer = "moreReading-module--bigCardContainer--2hvBT";
export var moreReading = "moreReading-module--moreReading--1zabh";
export var list = "moreReading-module--list--3ElU6";
export var leftContainer = "moreReading-module--leftContainer--3IuOo";
export var rightContainer = "moreReading-module--rightContainer--2bNT3";
export var middleContainer = "moreReading-module--middleContainer--3GBpg";
export var subtitle = "moreReading-module--subtitle--1AEts";
export var smallReadingCard = "moreReading-module--smallReadingCard--2RlIo";
export var mediumReadingCard = "moreReading-module--mediumReadingCard--20Luw";
export var bigReadingCard = "moreReading-module--bigReadingCard--MeQk1";
export var linkContainer = "moreReading-module--linkContainer--6lxIY";
export var label = "moreReading-module--label--2sliB";
export var hover = "moreReading-module--hover--3AVqZ";
export var imageContainer = "moreReading-module--imageContainer--1epqQ";
export var body = "moreReading-module--body--2K8qa";
export var description = "moreReading-module--description--43Z6w";
export var buttonLink = "moreReading-module--buttonLink--3VzDW";
export var withDesc = "moreReading-module--withDesc--1Am7b";
export var withButton = "moreReading-module--withButton--lriyu";
export var hoverContentContainer = "moreReading-module--hoverContentContainer--10SOd";
export var quoteText = "moreReading-module--quoteText--1dDci";
export var authorContainer = "moreReading-module--authorContainer--3S3DB";
export var fullWidth = "moreReading-module--fullWidth--jSYtX";
export var limited = "moreReading-module--limited--3YNy4";
export var storybook = "moreReading-module--storybook--Y_uZR";