import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import truncate from "lodash/truncate"
import upperFirst from "lodash/upperFirst"

import Image from "../image/image"
import FindOutMore from "../findOutMore/findOutMore"
import FormattedLink from "../formattedLink/formattedLink"
import { getPathAndLabel } from "./helpers/getPathAndLabel"
import Label from "../label/label"
import RichText from "../richText/richText"
import { useTranslation } from "../../helpers/useTranslation"

import * as styles from "./moreReading.module.scss"

const BigReadingCard = props => {
  const { description, logo, title, page, label, imageFullWidth } = props
  const { t } = useTranslation()
  const { url, label: entityLabel } = getPathAndLabel(page)
  const generatedLabel = t(entityLabel);

  const renderBody = () => {
    let desc = ""
    const isRichText = Array.isArray(description)

    if (isRichText) desc = <RichText blocks={description} />
    else desc = truncate(description, { length: 125, omission: " ...", separator: " " })

    return (
      <div className={classNames(styles.body, description && styles.withDesc)}>
        <h4>{title}</h4>
        <div className={classNames(styles.description, isRichText && styles.limited)}>{desc}</div>
        <FindOutMore className={styles.buttonLink} url={url} label={upperFirst(t("read more"))} />
      </div>
    )
  }

  return (
    <div className={styles.bigReadingCard}>
      <div className={classNames(styles.imageContainer, imageFullWidth && styles.fullWidth)}>
        <Image image={logo?.asset} />
      </div>
      <Label className={styles.label} title={generatedLabel} {...label} />
      {renderBody()}
      <FormattedLink url={url} className={styles.linkContainer} />
    </div>
  )
}

BigReadingCard.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.any)]),
  logo: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.shape({ title: PropTypes.string, color: PropTypes.string }),
  imageFullWidth: PropTypes.bool,
  page: PropTypes.objectOf(PropTypes.any).isRequired,
}

BigReadingCard.defaultProps = {
  description: "",
  label: {},
  imageFullWidth: true,
}

export default BigReadingCard
